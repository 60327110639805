  .parentContainer {
    display: flex;
    position: relative;
    height: auto;
    width: 100%;
    justify-content: center;
    margin: 8% 0%;
    align-items: stretch;
  }
  .childCotainer{
    display: flex;
    width: 80%;
    height: auto;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
  }
  .imageContainer {
    order: 1;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vw;
  }
  .squareBackGround {
    position: absolute;
    width: 100%;
    left: -60%;
    top: -20vw;
  }
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: antiquewhite;
  }
  .imageBox {
    box-shadow: var(--image-shadow);
    width: 100%;
    height: 45%;
    z-index: 2;
  }
  .superbrainTextAlign {
    text-align: right;
    margin-left: auto;
  }
  .descriptorStyle {
    width: 100%;
  }
  .logoTextContainer {
    margin-top: 5px;
    z-index: 2;
    align-self: center;
    order: 1;
  }
  .square {
    height: 140%;
    width: 140%;
    transform: rotate(45deg);
  }
  @media screen and (min-width: 768px) {
    .parentContainer{
      min-height: 500px;
      margin: 10% 0%;
    }
    .imageBox {
      min-height: 230px;
      height: 35%;
      width: 80%;
    }
    .imageContainer {
      flex: 1.1;
      height: auto;
    }
    .squareBackGround {
      align-self: center;
      width: 70%;
      left: -35%;
      top: unset;
    }
    .logoTextContainer{
      flex: 0.9;
    }
    .superbrainTextAlign {
      text-align: left;
      margin-left: 0;
      margin-right: auto;
    }
    .descriptorStyle {
      width: 90%;
    }
    .square {
      height: 120%;
      width: 120%;
    }
  }
  @media screen and (min-width:950px){
    .parentContainer{
      min-height: 650px;
      margin: 13% 0px;
    }
    .imageBox {
      min-height: 265px;
      height: 40%;
    }
  }
  @media screen and (min-width:1300px){
    .parentContainer{
      min-height: 800px;
    }
    .imageBox{
      min-height: 320px;
    }
  }
  @media screen and (min-width:1800px){
    .parentContainer{
      min-height: 1080px;
    }
  
    .imageBox{
      min-height: 40px;
    }
    
  }
  
  @media screen and (min-width:2500px){
    .parentContainer{
      min-height: 1400px;
    }
    .imageBox{
      min-height: 560px;
    }
  }